<template>
  <div class="sale-job-container">
    <div class="sale-list-tools">
      <el-form class="battery-form" :inline="true" :model="formData">
        <el-form-item label="设备编号">
          <el-input
            v-model="formData.sn"
            placeholder="设备编号"
            size="small"
            class="area-form-item"
            style="width: 180px"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="工单状态">
          <el-select
            v-model="formData.status"
            placeholder="信息状态"
            size="small"
            class="area-form-item"
          >
            <el-option label="全部" value=""></el-option>
            <el-option label="未维修" :value="1"></el-option>
            <el-option label="已维修" :value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="维修类型">
          <el-select
            v-model="formData.curStatus"
            placeholder="维修类型"
            size="small"
            class="area-form-item"
          >
            <el-option label="全部" value=""></el-option>
            <el-option label="已维修" :value="1"></el-option>
            <el-option label="已维修更换编号" :value="2"></el-option>
            <el-option label="无法维修建议处置" :value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="发生时间:">
          <el-date-picker
            v-model="formData.time"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="timeChange"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item style="float: right; marginright: 0px">
          <el-button type="primary" @click="getData()">搜索</el-button>
          <el-button @click="resetFrom()">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="sale-table-container">
      <div class="sale-table-content">
        <el-table style="width: 100%" height="500px" v-loading="loading" :data="list">
          <el-table-column label="设备编号" prop="sn" width="200">
            <template slot-scope="scope">
              <a @click="gotoDetail(scope.row)" style="color: blue">{{scope.row.sn || '-'}}</a>
            </template>
          </el-table-column>
          <el-table-column label="当前状态" prop="sender" width="200">
            <template slot-scope="scope">
              {{ scope.row.status | statusStr }}
            </template>
          </el-table-column>
          <el-table-column label="故障名称" prop="sendTime" width="200">
            <template slot-scope="scope">
              {{ scope.row.info || "-" }}
            </template>
          </el-table-column>
          <el-table-column label="派发时间" prop="status" width="200">
            <template slot-scope="scope">
              {{ scope.row.sendTime || '-' }}
            </template>
          </el-table-column>
          <el-table-column label="派发人" prop="ompStatus" width="200">
            <template slot-scope="scope">
              {{ scope.row.senderText || "-" }}
            </template>
          </el-table-column>
          <el-table-column label="持续时间" prop="ompStatus" width="200">
            <template slot-scope="scope">
              {{ scope.row.interval || "-" }}
            </template>
          </el-table-column>
          <el-table-column label="处理时间" prop="ompStatus" width="200">
            <template slot-scope="scope">
              {{ scope.row.finishTime || "-" }}
            </template>
          </el-table-column>
          <el-table-column label="操作" width="250">
            <template slot-scope="scope">
              <!-- <el-button @click="infoHandle(scope.row)" type="text"
                    >查看</el-button
                  > -->
              <el-button v-if="scope.row.status === 0" @click="setHandle(scope.row)" type="text"
                >处理</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="sale-table-page">
        <div class="sale-table-page-container">
          <el-pagination
            background
            :current-page="formData.pageIndex"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="formData.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <Handle v-if="visable" ref="handle" @refreshDataList="getData" />
  </div>
</template>

<script>
import axios from "@/utils/axios";
import "./Index.less";
import moment from "moment";
import Handle from "./components/Handle";
export default {
  name: "SaleJobIndex",
  components: {
    Handle,
  },
  data() {
    return {
      list: [],
      total: 0,
      visable: false,
      loading: false,
      formData: {
        sn: null,
        status: null,
        orderBy: null,
        pageIndex: 1,
        pageSize: 20,
        time: null,
        orderBy: null,
        organId: null,
      },
      activeName: "first",
    };
  },
  mounted() {
    this.formData.organId = localStorage.getItem('organId')
    this.formData.sn = this.$route.params.sn
    this.getData();
  },
  methods: {
    handleClick(tab, event) {
      this.activeName = tab.name;
      if (tab.name === "first") {
        this.getData();
      }
    },
    //获取列表数据
    getData() {
      this.loading = true
      axios
        .post(`/api/postSale/query`, this.formData)
        .then((res) => {
          const { list, total } = res;
          this.list = list;
          this.total = total;
          this.loading = false
        })
        .catch((err) => {
          this.loading = false
          this.$message.error(err);
        });
    },
    //重置
    resetFrom() {
      this.formData = {
        sn: null,
        status: null,
        orderBy: null,
        pageIndex: 1,
        pageSize: 20,
        time: null,
        orderBy: null,
        organId: localStorage.getItem('organId'),
      };
      this.getData();
    },
    //时间选择
    timeChange(data) {
      if (data) {
        this.search.startTime = this.DateToStr(data[0]);
        this.search.endTime = this.DateToStr(data[1]);
      } else {
        this.search.startTime = null;
        this.search.endTime = null;
      }
    },
    //日期转字符串
    DateToStr(date) {
      var year = date.getFullYear(); //年
      var month = date.getMonth(); //月
      var day = date.getDate(); //日
      var hours = date.getHours(); //时
      var min = date.getMinutes(); //分
      var second = date.getSeconds(); //秒
      return (
        year +
        "-" +
        (month + 1 > 9 ? month + 1 : "0" + (month + 1)) +
        "-" +
        (day > 9 ? day : "0" + day) +
        " " +
        (hours > 9 ? hours : "0" + hours) +
        ":" +
        (min > 9 ? min : "0" + min) +
        ":" +
        (second > 9 ? second : "0" + second)
      );
    },
    gotoDetail(row) {
      this.$router.push({
        name: "DeviceDetail",
        params: {
          sn: row.sn,
          imei: row.sn,
          type: 1
        },
      });
    },

    handleSizeChange(val) {
      this.formData.pageSize = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.formData.pageIndex = val;
      this.getData();
    },
    //跳转详情
    setHandle(item) {
      this.visable = true;
      this.$nextTick(() => {
        this.$refs.handle.init();
        this.$refs.handle.title = item.sn + " 售后处理";
        this.$refs.handle.dataForm.sn = item.sn;
        this.$refs.handle.dataForm.cloudId = item.cloudId;
        this.$refs.handle.dataForm.id = item.id
      });
    },
  },
  filters: {
    statusStr(value) {
      if (value !== null || value !== undefined) {
        if (value === 1) {
          return "已处理";
        } else if (value === 0) {
          return "未处理";
        } else {
          return "-";
        }
      } else {
        return "-";
      }
    },
  },
};
</script>